import React from "react"
import Layout from "../components/Layout"

import Tompiler from "../components/Content/Tompiler/Tompiler"

const tompiler = () => {
  return (
    <Layout>
      <Tompiler />
    </Layout>
  )
}

export default tompiler
